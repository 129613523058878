<template>
  <vx-card title="Sales Price Form">
    <vs-divider />
    <div class="vx-row mb-2">
      <div class="vx-col">
        <h4>Header</h4>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Code</label>
        <vs-input v-model="form.code" disabled class="w-full" />
      </div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">External Code</label>
        <vs-input
          v-model="form.external_code"
          class="w-full"
          :disabled="
            !showBtnSaveByStatus(this.$route.params.status, 'released')
          "
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Description</label>
        <vs-textarea
          v-model="form.description"
          :disabled="
            !showBtnSaveByStatus(this.$route.params.status, 'released')
          "
        />
      </div>
      <div class="vx-col md:w-1/4">
        <label class="vs-input--label">Valid From</label>
        <vs-input
          readonly
          v-model="form.valid_from"
          :disabled="true"
          type="date"
          class="w-full"
          :max="form.valid_to"
        />
        <!-- <date-range-picker
          style="min-height: 40px"
          class="w-full"
          ref="picker"
          opens="center"
          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
          :singleDatePicker="false"
          :timePicker="false"
          :timePicker24Hour="false"
          :showWeekNumbers="false"
          :showDropdowns="false"
          :autoApply="true"
          v-model="partial.valid_from_to"
          :linkedCalendars="true"
          :ranges="false"
          :disabled="
            !showBtnSaveByStatus(this.$route.params.status, 'released')
          "
        >
          <template v-slot:input="picker">
            {{ dateFormatDatePicker(picker.startDate) }} -
            {{ dateFormatDatePicker(picker.endDate) }}
          </template>
        </date-range-picker> -->
      </div>
      <div class="vx-col md:w-1/4">
        <label class="vs-input--label">Valid To</label>
        <vs-input
          v-model="form.valid_to"
          :disabled="true"
          type="date"
          class="w-full"
          :min="form.valid_from"
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Created By</label>
        <vs-input v-model="form.created_by" disabled class="w-full" />
      </div>
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Approved By</label>
        <vs-input v-model="form.approved_by" disabled class="w-full" />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Create Date</label>
        <vs-input
          typpe="date"
          v-model="form.create_date"
          disabled
          class="w-full"
        />
      </div>
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Approved Date</label>
        <vs-input v-model="form.approved_date" disabled class="w-full" />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Currency Code</label>
        <Currency
          @data="setCurrency"
          ref="selectCurrency"
          :isDisable="
            !showBtnSaveByStatus(this.$route.params.status, 'released')
          "
        />
      </div>
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label"
          >Attachment
          <small style="color: red"
            >(only: jpg, jpeg, pdf, doc, docx, png)</small
          ></label
        >
        <!-- only accept "jpg", "jpeg", "pdf", "doc", "docx", "png" -->
        <vs-input
          type="file"
          :disabled="
            !showBtnSaveByStatus(this.$route.params.status, 'released')
          "
          class="w-full"
          :multiple="true"
          @change="onChangeFileAttachments($event)"
          v-model="partial.attachments"
          accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        />
        <div v-show="priceID != null" class="my-3">
          <!-- list attachment -->
          <ul class="flex gap-2">
            <li
              v-for="(attachment, index) in form.file_attachments"
              :key="index"
            >
              <vs-button
                color="success"
                type="filled"
                class="small"
                target
                :href="{ url: attachment }"
                >Download File {{ index + 1 }}</vs-button
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Note</label>
        <vs-textarea
          v-model="form.note"
          :disabled="
            !showBtnSaveByStatus(this.$route.params.status, 'released')
          "
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Calculation</label>
        <br />
        <div class="flex gap-2 my-2">
          <vs-radio
            v-model="form.calculation"
            vs-name="calculation"
            vs-value="Step Ladder"
            :disabled="
              !showBtnSaveByStatus(this.$route.params.status, 'released')
            "
            >Step Ladder</vs-radio
          >
          <vs-radio
            v-model="form.calculation"
            vs-name="calculation"
            vs-value="Final"
            :disabled="
              !showBtnSaveByStatus(this.$route.params.status, 'released')
            "
            >Final</vs-radio
          >
        </div>
      </div>
      <!-- MIX2-301, flag active disembunyikan
      <div class="vx-col md:w-1/2" v-show="priceID != null">
        <label class="vs-input--label">Active</label>
        <br />
        <div class="flex gap-2 my-2">
          <vs-switch
            v-model="form.status"
            :disabled="
              !showBtnSaveByStatus(this.$route.params.status, 'released')
            "
            name="is active"
          />
        </div>
      </div> -->
    </div>
    <vs-divider />
    <div class="vx-row mb-2">
      <div class="vx-col">
        <h4>Applicable To:</h4>
      </div>
    </div>
    <div class="vx-row mb-2">
      <vs-tabs>
        <vs-tab label="Upload SKU">
          <!-- <div class="vx-col w-full">
            <label class="vs-input--label">Division</label>
            <Supplier @data="setSupplier" :isDisabled="disabledDivision" />
          </div> -->
          <div class="vx-col w-full">
            <label class="vs-input--label">Upload Template</label>
            <br />
            <div class="flex gap-2">
              <vs-input
                type="file"
                @change="onUploadTemplate"
                v-model="partial.upload_template"
                :disabled="!showBtnSaveByStatus(this.$route.params.status)"
              />
              <vs-button
                color="primary"
                size="small"
                type="filled"
                @click="clickDownload"
              >
                Download template
              </vs-button>
              <!-- <vs-button color="danger" @click="resetFilterDivision"
                >Reset</vs-button
              > -->
            </div>
            <!-- <div v-show="priceID != null" class="my-4">
              <vs-button
                color="success"
                type="filled"
                class="small"
                target
                :href="{ url: form.file_price_item_uploaded }"
                >Download File</vs-button
              >
            </div> -->
          </div>
          <div
            class="vx-col w-full"
            v-show="showBtnSaveByStatus(this.$route.params.status, 'released')"
          >
            <vs-button
              class="mt-2"
              color="primary"
              size="small"
              type="filled"
              @click="showTableItemUnit = !showTableItemUnit"
            >
              Browse Item
            </vs-button>
          </div>
        </vs-tab>
        <!-- <vs-tab label="Progress">
          <TableImport ref="tableImport" :filter="filterTableImport" />
        </vs-tab> -->
      </vs-tabs>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label for="">List Price Item</label>
        <vs-row
          :class="showTableItemUnit ? 'flex flex-wrap gap-2' : ''"
          class="justify-between"
        >
          <vs-col :vs-w="showTableItemUnit ? '5' : '12'">
            <FormTableListPriceItem
              :priceID="priceID"
              ref="formTableListPriceItem"
              :showEdit="showBtnSaveByStatus(this.$route.params.status)"
              :exceptStatus="this.$route.params.status"
              :headerValidFrom="form.valid_from"
              :headerValidTo="form.valid_to"
              :form="form"
            />
          </vs-col>

          <transition name="detail-fade">
            <vs-col v-if="showTableItemUnit" vs-w="6">
              <vs-row>
                <vs-col
                  style="padding-bottom: 8px"
                  vs-offset="8"
                  vs-type="flex"
                  vs-justify="rigth"
                  vs-align="rigth"
                  vs-w="4"
                >
                  <vs-button
                    class="ml-auto"
                    size="small"
                    v-on:click="handleClose"
                    color="grey"
                    icon-pack="feather"
                    icon="icon-x-square"
                    >Close</vs-button
                  >
                </vs-col>
              </vs-row>
              <TableItemUnit
                @close="handleClose"
                @data="setSelectedItemUnit"
                ref="tableItemUnit"
            /></vs-col>
          </transition>
        </vs-row>
      </div>
      <hr />
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Sales Channel</label>
        <SalesChannel
          @data="setSalesChannel"
          :isMultiple="true"
          ref="selectSalesChannel"
          :showAllLabel="true"
          :isDisable="
            !showBtnSaveByStatus(this.$route.params.status, 'released')
          "
        />
      </div>
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Territory</label>
        <territory
          @data="setTerritory"
          :isMultiple="true"
          :showAllLabel="true"
          :isDisable="
            !showBtnSaveByStatus(this.$route.params.status, 'released')
          "
          ref="selectTerritory"
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Customer</label>
        <Customer
          @data="setCustomer"
          ref="selectCustomer"
          :isMultiple="true"
          :showAllLabel="true"
          :isDisable="
            !showBtnSaveByStatus(this.$route.params.status, 'released')
          "
        />
      </div>
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Distribution Channel</label>
        <DistributionChannel
          @data="setDistrubutionChannel"
          :isMultiple="true"
          :showAllLabel="true"
          :isDisable="
            !showBtnSaveByStatus(this.$route.params.status, 'released')
          "
          ref="selectDistributionChannel"
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Pricing Group</label>
        <PricingGroup
          @data="setPricingGroup"
          ref="selectPricingGroup"
          :showAllLabel="true"
          :isMultiple="true"
          :isDisable="
            !showBtnSaveByStatus(this.$route.params.status, 'released')
          "
        />
      </div>
    </div>
    <div class="vx-row my-4">
      <div class="vx-col flex gap-3">
        <vs-button color="danger" type="filled" @click="clickBack">
          Cancel
        </vs-button>
        <vs-button
          color="primary"
          type="filled"
          @click="clickSave"
          v-show="showBtnSaveByStatus(this.$route.params.status)"
        >
          Save
        </vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { dateFormat, convertDateFormat } from "@/utils/helper.js";
import SalesChannel from "@/components/master/SalesChannel.vue";
import DistributionChannel from "@/components/master/DistributionChannel.vue";
import PricingGroup from "@/components/master/PricingGroup.vue";
import Supplier from "@/components/master/Supplier.vue";
import moment from "moment";
import Territory from "@/components/master/Territory.vue";
import Currency from "@/components/master/Currency.vue";
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import pricingManagementV3Repository from "@/repositories/pricingManagementV3Repository";
import FormTableListPriceItem from "./_components/_form-table-list-price-item.vue";
import Customer from "@/components/master/CustomerV2.vue";
import TableImport from "@/components/import/Table.vue";
import TableItemUnit from "@/components/master/TableItemUnit.vue";
import * as XLSX from "xlsx";

const PricingManagementV3Repository = RepositoryFactory.get(
  "pricingManagementV3"
);

export default {
  components: {
    TableImport,
    Currency,
    DateRangePicker,
    Territory,
    PricingGroup,
    SalesChannel,
    DistributionChannel,
    Supplier,
    FormTableListPriceItem,
    Customer,
    TableItemUnit,
  },
  data() {
    return {
      filterTableImport: {
        type: "price-management-v3",
        source: "OMS",
      },
      showTableItemUnit: false,
      isDetail: false,
      code: "",
      created_by: "",
      form: {
        external_code: "",
        code: "",
        description: "",
        valid_from: "",
        valid_to: "",
        attachments: [],
        note: "",
        created_by: "",
        approved_by: "",
        create_date: moment().format("YYYY-MM-DD"),
        approved_date: null,
        currency_id: 0,
        distribution_channel_ids: [],
        supplier_id: 0,
        sales_channel_ids: [],
        pricing_group_ids: [],
        territory_ids: [],
        file_price_item: null,
        status: false,
        calculation: "",
        customer_ids: [],
      },
      partial: {
        attachments: null,
        valid_from_to: {
          startDate: null,
          endDate: null,
        },
        upload_template: null,
      },
      submitForm: null,
      disabledDivision: false,
      priceID: null,
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    if (id != null) {
      // parse string to number
      this.priceID = parseInt(id);
      await this.getDetail(id);
    } else {
      // init fetch
      await this.$refs.selectCustomer.getData();

      setTimeout(() => {
        if (this.form.currency_id == 0) {
          let result =
            this.$refs.selectCurrency.setDefaultSelectedDataByCode("IDR");

          if (result[0].id != null) {
            this.form.currency_id = result[0].id;
          } else if (result[0].ID != null) {
            this.form.currency_id = result[0].ID;
          }
        }
        if (this.form.calculation == "") {
          this.form.calculation = "Final";
        }
      }, 2000);
    }

    // set isDetail
    const isDetail = this.$route.params.isDetail;
    if (isDetail != null) {
      this.isDetail = isDetail;
    }
  },
  methods: {
    handleClose() {
      this.showTableItemUnit = false;
    },
    convertDateFormat,
    readExcelPriceItemUploaded(files) {
      this.$refs.formTableListPriceItem.deleteAll();
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const wb = XLSX.read(arrayBuffer, { type: "array" });
        const sheetName = wb.SheetNames[0];
        const worksheet = wb.Sheets[sheetName];
        let data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        // remove index 0
        data.shift();
        let results = this.setDTOTablePriceItem(data);

        // set to form list table price item component
        for (let data of results) {
          this.$refs.formTableListPriceItem.setSelectedData(data);
        }
      };
      reader.readAsArrayBuffer(files);
    },
    setDTOTablePriceItem(records) {
      console.log(records);
      let data = [];
      records.forEach((record) => {
        // convert date 01/01/2021 to 2021-01-01
        let valid_from = this.convertDateFormat(record[8]);
        let valid_to = this.convertDateFormat(record[9]);

        let item = {
          id: null,
          code: record[0],
          name: record[1],
          unit: record[2],
          qty_from: record[3],
          qty_to: record[4],
          price: record[5],
          discountable: null,
          markup: record[7],
          notes: record[6],
          valid_from: valid_from,
          valid_to: valid_to,
        };
        data.push(item);
      });
      return data;
    },
    setSelectedItemUnit(data) {
      this.$refs.formTableListPriceItem.setSelectedData(data);
    },
    showBtnSaveByStatus(status, except) {
      if (status == "draft" || status == "create" || status == "released") {
        if (this.isDetail && except == "released") {
          return false;
        }

        return true;
      }
      return false;
    },
    setCustomer(data) {
      this.form.customer_ids = [];
      data.forEach((item) => {
        if (item.id != null) {
          this.form.customer_ids.push(item.id);
        } else if (item.ID != null) {
          this.form.customer_ids.push(item.ID);
        }
      });
    },
    async setForm(data) {
      this.form.external_code = data.external_code;
      this.form.code = data.code;
      this.form.description = data.description;
      this.form.valid_from = dateFormat(
        data.validate_from_to.start_date,
        "YYYY-MM-DD"
      );
      this.form.valid_to = dateFormat(
        data.validate_from_to.end_date,
        "YYYY-MM-DD"
      );
      // this.partial.valid_from_to.startDate = data.validate_from_to.start_date;
      // this.partial.valid_from_to.endDate = data.validate_from_to.end_date;
      this.form.note = data.notes;
      this.form.created_by = data.created_by;
      this.form.approved_by = data.approved_by;
      this.form.create_date = dateFormat(data.created_date, "YYYY-MM-DD");
      this.form.approved_date =
        data.approved_date != null
          ? dateFormat(data.approved_date, "YYYY-MM-DD")
          : null;
      await this.$refs.selectCurrency.setSelectedDataByIDs([data.currency_id]);
      this.form.currency_id = data.currency_id;
      if (data.distribution_channel != null) {
        await this.$refs.selectSalesChannel.setSelectedData(data.sales_channel);
      }
      if (data.distribution_channel != null) {
        await this.$refs.selectDistributionChannel.setSelectedData(
          data.distribution_channel
        );
      }

      if (data.pricing_group != null) {
        this.form.pricing_group_ids = [];
        this.form.pricing_group_ids = await data.pricing_group.map((item) => {
          return item.id;
        });
        await this.$refs.selectPricingGroup.setSelectedData(data.pricing_group);
      }
      if (data.territory != null) {
        await this.$refs.selectTerritory.setSelectedData(data.territory);
      }
      this.form.status = data.status;
      this.form.calculation = data.calculation;
      this.form.file_attachments = data.file_attachments;
      this.form.file_price_item_uploaded = data.file_price_item_uploaded;
      if (data.customer != null) {
        this.form.customer_ids = await data.customer.map((item) => {
          return item.id;
        });
        await this.$refs.selectCustomer.setSelectedData(data.customer);
      } else {
        await this.$refs.selectCustomer.setSelectedData({
          id: 0,
          code: "",
          name: "All",
        });
      }
    },
    async getDetail(priceID) {
      await this.$vs.loading();
      try {
        const response = await pricingManagementV3Repository.getPriceDetail(
          priceID
        );

        if (response.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: response.message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check-circle",
          });
          await this.setForm(response.data);
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: error.message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    async resetFilterDivision() {
      this.form.supplier_id = 0;
      this.partial.upload_template = null;
      this.form.file_price_item = null;
      this.disabledDivision = false;
    },
    async onUploadTemplate(event) {
      this.partial.upload_template = null;
      this.form.file_price_item = event.target.files[0];
      // disabled division
      this.disabledDivision = true;

      await this.readExcelPriceItemUploaded(event.target.files[0]);
    },
    async setCurrency(data) {
      if (data.id != null) {
        this.form.currency_id = data.id;
      } else if (data.ID != null) {
        this.form.currency_id = data.ID;
      }
    },
    async validateForm() {
      // if (this.form.external_code == "") {
      //   this.$vs.notify({
      //     title: "Error",
      //     text: "External Code is required",
      //     color: "danger",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return false;
      // }

      if (this.form.currency_id == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Currency is required",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return false;
      }

      // if (this.form.distribution_channel_ids.length == 0) {
      //   this.$vs.notify({
      //     title: "Error",
      //     text: "Distribution Channel is required",
      //     color: "danger",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return false;
      // }

      // if (this.form.supplier_id == 0) {
      //   this.$vs.notify({
      //     title: "Error",
      //     text: "Supplier is required",
      //     color: "danger",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return false;
      // }

      // if (this.form.pricing_group_ids.length == 0) {
      //   this.$vs.notify({
      //     title: "Error",
      //     text: "Pricing Group is required",
      //     color: "danger",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return false;
      // }

      // formated payload
      // if (
      //   this.partial.valid_from_to.startDate == null ||
      //   this.partial.valid_from_to.endDate == null
      // ) {
      //   this.$vs.notify({
      //     title: "Error",
      //     text: "Valid From / To is required",
      //     color: "danger",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return false;
      // }

      if (this.form.valid_from == "" || this.form.valid_to == "") {
        this.$vs.notify({
          title: "Error",
          text: "Valid From / To is required",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return false;
      }

      this.form.valid_from = dateFormat(this.form.valid_from, "YYYY-MM-DD");
      this.form.valid_to = dateFormat(this.form.valid_to, "YYYY-MM-DD");

      // check file is null
      // if (this.priceID == null) {
      // if (this.form.file_price_item == null) {
      //   this.$vs.notify({
      //     title: "Error",
      //     text: "File template is required",
      //     color: "danger",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return false;
      // }
      // }

      // check calculation
      if (this.form.calculation == "") {
        this.$vs.notify({
          title: "Error",
          text: "Calculation is required",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return false;
      }
      console.log("form", this.form);

      // convert to form data
      let formData = new FormData();
      formData.append("external_code", this.form.external_code);
      formData.append("code", this.form.code);
      formData.append("description", this.form.description);
      formData.append("valid_from", this.form.valid_from);
      formData.append("valid_to", this.form.valid_to);
      for (let attachments of this.form.attachments) {
        formData.append("attachments[]", attachments);
      }
      formData.append("note", this.form.note);
      formData.append("created_by", this.form.created_by);
      formData.append("approved_by", this.form.approved_by);
      formData.append("create_date", this.form.create_date);
      formData.append("approved_date", this.form.approved_date);
      formData.append("currency_id", this.form.currency_id);
      for (let distribution_channel_ids of this.form.distribution_channel_ids) {
        formData.append("distribution_channel_ids[]", distribution_channel_ids);
      }
      formData.append("supplier_id", this.form.supplier_id);
      for (let sales_channel_ids of this.form.sales_channel_ids) {
        formData.append("sales_channel_ids[]", sales_channel_ids);
      }
      for (let id of this.form.pricing_group_ids) {
        formData.append("pricing_group_ids[]", id);
      }
      for (let territory_ids of this.form.territory_ids) {
        formData.append("territory_ids[]", territory_ids);
      }
      let data =
        await this.$refs.formTableListPriceItem.getListPriceItemAsExcelFile();
      formData.append("file_price_item", data, "file_price_item.xlsx");
      formData.append("status", this.form.status);
      formData.append("calculation", this.form.calculation);
      console.log("form customer ids", this.form.customer_ids);
      for (let customer_ids of this.form.customer_ids) {
        formData.append("customer_ids[]", customer_ids);
      }
      this.submitForm = formData;

      return true;
    },
    async clickSave() {
      let validation = await this.validateForm();
      if (!validation) {
        return false;
      }
      this.$vs.loading();
      // for update
      const id = this.$route.params.id;
      if (id != null) {
        this.submitForm.append("price_id", id);
      }

      try {
        const headers = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const response = await pricingManagementV3Repository.createSku(
          this.submitForm,
          headers
        );

        if (response.code == 200) {
          this.$vs.notify({
            time: 6000,
            title: "primary",
            text: "Processing data in the background, please check tab log import",
            color: "success",
            iconPack: "feather",
            icon: "icon-check-circle",
          });

          this.form.code = response.data.code;
          this.form.created_by = response.data.created_by;
          if (this.priceID == 0) {
            this.resetForm();
          }

          // redirect to index
          this.$router.push({ name: "pricing-management-v3" });
        } else {
          this.$vs.notify({
            title: "Error",
            text: response.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while saving data",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });

        console.log(error);
      }
      this.$vs.loading.close();
    },
    async resetForm() {
      this.form.code = "";
      this.form.description = "";
      this.form.valid_from = "";
      this.form.valid_to = "";
      this.form.attachments = [];
      this.form.note = "";
      this.form.created_by = "";
      this.form.approved_by = "";
      this.form.create_date = moment().format("YYYY-MM-DD");
      this.form.approved_date = null;
      this.form.currency_id = 0;
      this.form.distribution_channel_ids = [];
      this.form.supplier_id = 0;
      this.form.sales_channel_ids = [];
      this.form.pricing_group_ids = [];
      this.form.territory_ids = [];
      this.form.file_price_item = null;
      this.form.status = true;
      this.form.calculation = "";
      this.partial.attachments = [];
      this.form.valid_from = "";
      this.form.valid_to = "";
      this.partial.valid_from_to = {
        startDate: null,
        endDate: null,
      };
      this.submitForm = null;
      this.getCode();
    },
    async onChangeFileAttachments(events) {
      this.form.attachments = [];
      const files = events.target.files;
      files.forEach((file) => {
        this.form.attachments.push(file);
      });
    },
    setTerritory(data) {
      this.form.territory_ids = [];
      data.forEach((item) => {
        this.form.territory_ids.push(item.id);
      });

      // set load customer select by territory id like this url?territory_id=1&territory_id=2
      this.$refs.selectCustomer.setTerritoryIDs(this.form.territory_ids);
    },
    async setPricingGroup(datas) {
      this.form.pricing_group_ids = [];
      datas.forEach((data) => {
        if (data.id != null) {
          this.form.pricing_group_ids.push(data.id);
        } else if (data.ID != null) {
          this.form.pricing_group_ids.push(data.ID);
        }
      });
    },
    async setDistrubutionChannel(datas) {
      this.form.distribution_channel_ids = [];
      datas.forEach((data) => {
        if (data.id != null) {
          this.form.distribution_channel_ids.push(data.id);
        } else if (data.ID != null) {
          this.form.distribution_channel_ids.push(data.ID);
        }
      });
    },
    async setSalesChannel(datas) {
      this.form.sales_channel_ids = [];
      datas.forEach((data) => {
        this.form.sales_channel_ids.push(data.id);
      });
    },
    async setSupplier(supplier) {
      this.form.supplier_id = supplier.ID;
    },
    async clickDownload() {
      // if (this.form.supplier_id == 0) {
      //   this.$vs.notify({
      //     title: "Error",
      //     text: "Supplier is required",
      //     color: "danger",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return false;
      // }
      this.$vs.loading();
      try {
        const supplier_id = this.form.supplier_id;
        const priceID = this.priceID;
        let params = {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
          params: {
            supplier_id: supplier_id,
            price_id: priceID,
          },
        };

        const response = await PricingManagementV3Repository.downloadExcelSku(
          params
        );
        var fileURL = window.URL.createObjectURL(new Blob([response]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        let fileTitle = `TEMPLATE-SKU`;

        fileLink.setAttribute("download", fileTitle + ".xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (error) {
        console.log(error);
        this.$vs.notify({
          title: "Error",
          text: "Error while downloading template",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
      this.$vs.loading.close();
    },
    async getCode() {
      try {
        const response = await PricingManagementV3Repository.getCode("LP");
        if (response.code == 200) {
          this.form.code = response.data;
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching code",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
    },
    clickBack() {
      this.$router.push({ name: "pricing-management-v3" });
    },
    dateFormat,
    dateFormatDatePicker(date) {
      let a = null;
      if (date != null) {
        a = dateFormat(date, "DD/MM/yyyy");
      }
      return a;
    },
  },
};
</script>
